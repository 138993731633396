<template>
    <div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="relative">
            <div class="max-w-7xl mx-auto">
                <div data-aos="fade-right" data-aos-duration="1200" class="relative">
                <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                    <div class="text-center lg:text-left">
                    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-6xl md:text-6xl">
                        <span class="xl:inline">WinterZone </span>
                        <span class="text-blue-300 xl:inline">Interactive</span>
                    </h1>
                    <p class="mt-3 mb-12 text-base text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Lösningar för alla. Oavsett om det är en enkel webbplats för att redovisa vad ditt bolag sysslar med, bloggsystem eller något helt annat så finns vi här vid din sida och bygger något från grunden.
                    </p>
                        <div class="mt-8 md:mt-5 sm:flex sm:justify-center lg:justify-start">
                            <div class="rounded-md shadow">
                            <router-link to="contact">
                                <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 md:py-4 md:text-lg md:px-10">
                                    Kontakta oss
                                </a>
                            </router-link>
                            </div>
                            <div class="mt-3 sm:mt-0 sm:ml-3">
                            <a class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-400 bg-blue-100 md:py-4 md:text-lg md:px-10 cursor-default <!--hover:bg-blue-300 hover:text-white-->">
                                Projektportalen*
                            </a>
                            </div>
                        </div>
                        <p class="mt-2 text-gray-300 uppercase font-mono">* Kommer snart.</p>
                    </div>
                </main>
                </div>
            </div>
            <div data-aos="zoom-in-right" data-aos-duration="1200" class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden xl:block">
                <img class="w-9/12" src="../assets/img/interactive/dev.png" alt="">
            </div>
        </div>


        <div class="p-12 sm:p-36 mt-96 bg-gray-800">
            <div class="text-center">
                <p data-aos="fade-in" data-aos-duration="1200" class="mt-2 mb-12 text-3xl leading-8 font-extrabold tracking-tight text-white text-center sm:text-4xl">
                    Prisuppskattning
                </p>
            </div>

            <div class="flex flex-col lg:flex-row">
                <div data-aos="fade-right" data-aos-duration="1200" class="bg-white text-center lg:w-1/2 lg:mx-6 p-8 rounded-lg mt-6">
                    <span class="text-blue-500 bg-blue-100 font-medium uppercase tracking-wide rounded-full px-4 py-0.5">Standard</span>
                    <p class="text-gray-800 font-extrabold mt-3 text-3xl md:text-6xl">5-12kkr</p>
                    <p class="mt-6 text-gray-500 text-xl">En landningssida för ditt företag och dess podukter eller dylikt.</p>
                </div>
                <div data-aos="fade-left" data-aos-duration="1200" class="bg-white text-center lg:w-1/2 g:mx-6 p-8 rounded-lg mt-6">
                    <span class="text-yellow-500 bg-yellow-100 font-medium uppercase tracking-wide rounded-full px-4 py-0.5">Premium</span>
                    <p class="text-gray-800 font-extrabold mt-3 text-3xl md:text-6xl">15kkr+</p>
                    <p class="mt-6 text-gray-500 text-xl">Alla möjliga sorters webb- och applösningar där en databas är inblandad.</p>
                </div>
            </div>

        </div>
    </div>
</template>