<template>

  <div class="pl-20 pr-20">
    <h1 class="text-center mt-8 text-3xl text-green-300 pt-8">Dataskyddspolicy WinterZone AB</h1>
    <p class="text-gray-500 italic text-center">WinterZone AB:s Dataskydsspolicy gäller från och med 2021-05-01.</p><br><br>
    <h2 class="text-green-300 text-xl font-bold">Vilken information samlar vi in och varför</h2><br>
    <p>Vi samlar in din information för att för att kunna skicka dina beställningar hem till dig.
      Med detta följer att vi även måste spara vissa uppgifter om dina köp i ett antal år på grund av lagkrav.
    </p><br>
    <p class="font-bold">
      Information som du lämnar till oss
    </p>
    <ul>
      <li><p>När du skapar ett konto på webshop.winterzone.se alternativt shop.winterzone.se. Främst personlig information</p></li>
      <li><p>När du genomför ett köp. Information om din betalning.</p></li>
      <li><p>När du är i kontakt med oss. Kommunikation med kundtjänst och serviceärenden.</p></li>
    </ul>
    <br/><p class="font-bold">Information vi samlar om dig:</p>
    <ul>
      <li><p>Aktivitet på webshop.winterzone.se alternativt shop.winterzone.se (cookies)</p></li>
    </ul>
    <br/><p class="font-bold">Varför och hur länge vi behandlar din information</p>
    <p>För att vi ska kunna leverera varor till dig, eller för att sedan följa upp ett kundservice ärende, så innebär
      det att vi behöver behandla ett antal uppgifter om dig. Här nedan beskriver vi de övergripande syftena, vilken
      grund vi behandlar uppgifterna och hur länge vi sparar informationen.</p>

    <br/><table class="table-auto">
      <thead>
      <tr>
        <th class="p-2 text-left">Syftet med behandlingen</th>
        <th class="p-2 text-left">Rättslig grund för behandlingen</th>
        <th class="p-2 text-left">Hur länge vi sparar uppgifterna</th>
      </tr>
      </thead>
      <tbody>
      <tr class="p-4 bg-gray-100">
        <td class="p-2">Ditt konto på webshop.winterzone.se alternativt shop.winterzone.se samt historik
          för ordrar, kommunikation och serviceärenden</td>
        <td class="p-2">Samtycke</td>
        <td class="p-2">Tills du väljer att ta bort ditt konto</td>
      </tr>
      <tr>
        <td class="p-2">Nyhetsbrev</td>
        <td class="p-2">Samtycke</td>
        <td class="p-2">Tills du drar tillbaka ditt samtycke (opt-out)</td>
      </tr>
      <tr class="bg-gray-100">
        <td class="p-2">För att administrera ditt köp och leverera varor</td>
        <td class="p-2">Nödvändigt på grund av avtal</td>
        <td class="p-2">3 år (+ leveranstiden)</td>
      </tr>
      <tr>
        <td class="p-2">Utföra och hantera serviceärenden</td>
        <td class="p-2">Nödvändigt på grund av avtal</td>
        <td class="p-2">3 år</td>
      </tr>
      <tr class="bg-gray-100">
        <td class="p-2">Kommunikation och kundservice</td>
        <td class="p-2">Berättigat intresse</td>
        <td class="p-2">3 år</td>
      </tr>
      <tr>
        <td class="p-2">För felsökning, dataanalys, statistik och motverka missbruk (10 dagar)</td>
        <td class="p-2">Berättigat intresse</td>
        <td class="p-2">3 år</td>
      </tr>
      <tr class="bg-gray-100">
        <td class="p-2">Bokföring av transaktioner</td>
        <td class="p-2">Rättslig skyldighet</td>
        <td class="p-2">7 år</td>
      </tr>
      </tbody>
    </table>

    <br><br><h2 class="text-green-300 text-xl font-bold">Marknadsföring och annonsering</h2><br>
    <p>Vi delar <span class="font-bold">ingen</span> information med annonspartners för att skicka reklammeddelanden
      till dig eller för att visa dig mer skräddarsytt innehåll.</p>

    <br><br><h2 class="text-green-300 text-xl font-bold">Dina rättigheter</h2><br>
    <p>Eftersom vi behandlar din information så är det också viktigt att du känner till vilka rättigheter du har som
      kund. Här nedan hittar du information om vilka rättigheter du har och hur de förhåller sig till hur vi behandlar
      den
    </p>
    <ul class="list-disc ml-10">
      <li><p>Rätt till registerutdrag. Du har rätt att begära en kopia av de personuppgifter vi har lagrade om dig.
        Du kan skicka din begäran om registerutdrag till support@winterzone.se.</p></li>
      <li><p>Rätt till rättelse. Om vi har felaktig eller ej komplett information om dig så har du rätt att när som
        helst begära att vi korrigerar den.</p></li>
      <li><p>Rätt till radering. Du har rätt att begära att vi raderar information om dig som vi behandlar med ditt
        samtycke, om du väljer att dra tillbaka ditt samtycke så har du också rätt att begära att den informationen
        raderas. Du kan begära att vi raderar ditt konto med tillhörande uppgifter. Vi kommer däremot behålla vissa
        uppgifter under den tid som vi är rättsligt skyldiga för.</p></li>
      <li><p>Rätt till dataportabilitet. Du har rätt att begära att vi skickar din data till en annan organisation,
        eller att få ut den själv i ett vanligt förekommande format (XML, JSON, CSV, HTML). Du kan skicka din begäran
        om registerutdrag till support@winterzone.se.</p></li>
      <li><p>Rätt att invända mot behandling. När vi behandlar din information på grunden “legitimt intresse” så har du
        rätt invända mot behandlingen på grund av skäl som hänför sig till din specifika situation och som vi inte
        rimligen hade kunnat förutse. Om du invänder mot sådan behandling måste vi antingen påvisa tvingande
        berättigande skäl som väger tyngre än dem skäl du angett, eller upphöra med just den behandlingen av dina
        uppgifter.</p></li>
      <li><p>Rätt att inge klagomål till tillsynsmyndigheten. Om du inte är nöjd med hur vi behandlar din information
      så hoppas vi att du berättar detta för oss så att vi kan bli bättre på att förvalta ditt förtroende. Men du har
      oavsett alltid rätt att inge ett klagomål till Integritetsskyddsmyndigheten,
        <a class="bg-green-200 underline" href="https://www.imy.se/">https://www.imy.se/</a></p></li>
    </ul>

    <br><br><h2 class="text-green-300 text-xl font-bold">Säkerhet kring din information</h2><br>
    <p>WinterZone AB sätter din personliga integritet i första rummet och arbetar därför aktivt för att dina personuppgifter
      behandlas med största försiktighet. WinterZone AB vidtar de åtgärder som krävs för att säkerställa att dina personuppgifter
      behandlas säkert och i enlighet med denna policy och dataskyddsförordningen.
    </p>
    <br><br><h2 class="text-green-300 text-xl font-bold">Barn</h2><br>
    <p>Våra tjänster riktar sig inte till barn under 13 år. Vi samlar inte medvetet in personuppgifter från barn som är
      under 13 år. Om du är förälder till ett barn och blir medveten om att ditt barn har lämnat personuppgifter till
      oss, vänligen kontakta oss på support@winterzone.se så att du kan begära att få utöva dina tillämpliga tillgångs-,
      rättelse-, uppsägnings- och/eller invändningsrättigheter.
    </p>
    <br><br><h2 class="text-green-300 text-xl font-bold">Cookies</h2><br>
    <p>När du besöker vår hemsida kommer information om användandet att lagras genom cookies. Cookies är passiva
      textfiler som lagras i din webbläsare, mobil eller surfplatta. WinterZone AB använder cookies för att underlätta din
      användning av hemsidan och för att inhämta information. Ett typiskt exempel på syftet med cookies är att vi med hjälp av 
      cookies kan se till att din webbläsare kommer ihåg vad du lagt in i din kundvagn, utan en cookie som kommer ihåg detta så
      glömer sidan helt enkelt bort vad det är du vill köpa.

      Du kan välja att låta din webbläsare blockera kakor men om du väljer att göra detta kan delar av hemsidan sluta fungera.
    </p>
    <br><br><h2 class="text-green-300 text-xl font-bold">Kontaktuppgifter</h2><br>
    <p>Winterzone AB, org.nr. 559253-8861, är personuppgiftsansvariga för behandlingen av dina personuppgifter. Har du frågor
      gällande vår hantering av dina personuppgifter är du välkommen att kontakta oss på support@winterzone.se.
    </p>
  </div>

</template>