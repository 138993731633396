<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
          <main class="px-4 mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div class="text-center lg:text-left">
                <h1 class="mb-24 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                    <span class="block xl:inline">Är det dags att höra av sig?</span>
                </h1>
                <div data-aos="fade-right" data-aos-duration="1200" >
                    <p class="mt-3 text-base font-bold text-purple-600 md:text-3xl lg:mx-0">
                        Kundtjänst
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        support@winterzone.se
                    </p>
                </div>
                <div data-aos="fade-right" data-aos-duration="1200" >
                    <p class="mt-3 text-base font-bold text-pink-600 md:text-3xl lg:mx-0">
                        Sälj
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        sales@winterzone.se
                    </p>
                </div>
                <br>
                <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="150">
                    <p class="mt-10 text-base font-bold text-gray-600 md:mt-3 lg:mx-0">
                        Verkställande direktör<span class="font-normal">, Martin Peterson</span>
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        mail: ceo@winterzone.se
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        tfn/<a class="underline hover:text-pink-400" href="https://www.signal.org/">Signal</a>: +46 (0)72-368 63 90
                    </p>
                </div>
                <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="300">
                    <p class="mt-10 text-base font-bold text-gray-600 md:mt-3 lg:mx-0">
                        Teknisk chef<span class="font-normal">, Sixten Peterson</span>
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        cto@winterzone.se
                    </p>
                    <p class="text-xl text-gray-400 lg:mx-0">
                        tfn/<a class="underline hover:text-pink-400" href="https://www.signal.org/">Signal</a>: +46 (0)73-342 65 85
                    </p>
                </div>
            </div>
          </main>
      </div>
    </div>
  </div>
</template>