<template>
    <div class="pl-20 pr-20">
        <h1 class="text-center mt-8 text-3xl text-pink-300 pt-8">Privacy Policy</h1>
        <p class="text-gray-500 italic text-center">Amended: 2021-04-17</p><br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>1.0</strong> Overview</h2><br>
        <p>
            <strong>1.1</strong> This Privacy Policy applies to all information collected about you by WinterZone AB, regardless of how it is 
                collected or stored, and describes, among other things, the types of information collected about you when you interact with the Services, how your 
                information may be used and how your information is protected.<br>
                Except as otherwise noted in this Privacy Policy, WinterZone AB is a data controller (as that term is used under the EU General Data Protection Regulation ("GDPR")), which means that we decide how and why the information you provide to us is processed. This Policy may be amended or updated from time to time to reflect changes in our practices with respect to the Processing of your information, or changes in applicable law. We encourage you to read this Policy carefully, and to regularly check this page to review any changes we might make.
                <br>This website is operated by WinterZone AB. Throughout the site, the terms “we”, “us” and “our” refer to WinterZone AB. 
                WinterZone AB offers this website, including all information, tools and services available from this site to you, the user, 
                conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>2.0</strong> Personal Information</h2><br>
        <p>
            <strong>2.1</strong> "Personal Information" means information that is about any individual, 
                or from which any individual is directly or indirectly identifiable.
        </p><br>
        <p>
            <strong>2.2</strong> We may Process: your personal details (e.g., your name); demographic data (e.g., your nationality); 
                your contact details (e.g., your email); records of your consents; purchase details; information about your interactions with our content; 
                and any views or opinions you provide to us.
                We may also Process information about you from your use of our Services (e.g., the type of plan you are using, the amount of ram, etc.),
                 including your interactions with content on the Services.
        </p><br>
        <p>
            <strong>2.3</strong> We may Process the following categories of Personal Information about you:
                <br>
                <ol class="list-decimal ml-10">
                    <li>Personal details: your name; username or log in details and password.</li>
                    <li>Demographic information: nationality and language preferences.</li>
                    <li>Contact details: postal address and email address.</li>
                    <li> Consent records: records of any consents you may have given, together with the date and time, means of consent and any related information 
                    (e.g., the subject matter of the consent).</li>
                    <li>Location information: location data that describes the precise geographic location of your device ("Precise Location Data").</li>
                    <li>Purchase and payment details: records of purchases and prices; invoice records; payment records; billing address; payment method; payment amount; and payment date.</li>
                </ol>
        </p><br>
        <p>
            <strong>2.4</strong> Personal information such as first and last name and e-mail address are never shared with third parties, 
                commercial or individual. Client accounts are kept indefinitely for transaction logging purposes but can be removed upon request after 6 months 
                of account closure, or no active services. Information may be provided to law enforcement if proper lawful and legal court order is provided.
        </p><br>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>3.0</strong> Client Service Data</h2><br>
        <p><strong>3.1</strong> Information hosted on our servers by client will never be accessed, saved, 
                or modified by any WinterZone AB representative without initial notification to and consent of the client, unless 
                client requests technical support prior that may require accessing server data; excluding any offered automated 
                backups. Client server data backups are not accessible by technical support or billing representatives, escalation 
                of data request for service restoration purposes only, must be passed to management.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>4.0</strong> Cookies!</h2><br>
        <p>
            <strong>4.1</strong> What are cookies? A cookie is simply a technology for remembering something about you. Without cookies, 
                a website is like a goldfish who loses its memory every time you visit a new page. Once you visit a new page, it doesn’t remember who you are.
                Without any memory, a website can’t do a lot of stuff. It can’t let you log in, because it forgets who you are. It can’t let you buy anything,
                because it forgets what you’re buying. But it also means it can’t track you. Some websites use cookies to remember what you do on their website,
                and to target ads at you. And some of those websites share their cookies, so that ads on one website know what you liked on another. This has 
                scared a lot of people. Cookies aren’t automatically good or bad, but it’s worth understanding what you can do about them. You can turn them 
                off completely, which is a bit like banning all music to prevent another Justin Bieber album. Many websites simply won’t work. A better option
                would be to turn off 3rd party cookies, which will stop most websites from sharing information about you. Some browsers – like Safari – do this
                automatically. And finally, you can take a deeper look into any websites which concern you. Most websites have policies that explain what they
                do, if you care to look. Well over 90% of websites use cookies. Cookies aren’t automatically good or bad, but it’s worth understanding what you
                can do about them. For more information about cookies go to <a class="text-pink-300 hover:text-pink-400" href="https://cookies.insites.com/">https://cookies.insites.com/</a>
                <br><strong>Technical explanation:</strong> Cookies are files with small amount of data which may include an anonymous unique identifier. 
                Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to
                 collect and track information and to improve and analyze our Service.
        </p><br>
        <p>
            <strong>4.2</strong> We use cookies and similar tracking technologies to track the activity on our site/service and hold certain 
                information. <br>Examples of cookies we use:
                <br>
                <ol class="list-decimal ml-10">
                    <li>Preference Cookies. Help remember and process the items in your shopping cart.</li>
                    <li>Preference Cookies. Understand and save user's preferences for future visits.</li>
                    <li>Security Cookies. We use Security Cookies for security purposes.</li>
                </ol>
        </p><br>
        <p>
            <strong>4.3</strong> You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
                However, if you do not accept cookies, you may not be able to use some portions of our site/service.
        </p>
    </div>
</template>