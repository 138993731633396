<template>
  <div>
    <TermPage />
  </div>
</template>

<script>
import TermPage from '@/components/terms/3d/forsaljning.vue'

export default {
  name: 'Term',
  components: {
    TermPage
  }
}
</script>
