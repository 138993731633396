<template>
  <div>
    <HostingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import HostingPage from '@/components/HostingPage.vue'

export default {
  name: 'Hosting',
  components: {
    HostingPage
  }
}
</script>
