<template>
  <div>
    <threedPage />
  </div>
</template>

<script>
// @ is an alias to /src
import threedPage from '@/components/threedPage.vue'

export default {
  name: '3d',
  components: {
    threedPage
  }
}
</script>
