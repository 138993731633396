<template>
    <div>
        <h1 class="text-center mt-10 text-2xl">Villkor för WinterZone 3D</h1>
        <ul class="flex flex-col text-center md:flex-row md:justify-around underline text-blue-300 p-12">
            <router-link to="/forsaljning">
                <li class="p-3">Försäljningsvillkor</li>
            </router-link>
            <router-link to="/3d-forsaljning">
                <li class="p-3">Försäljningsvillkor 3d-printtjänst</li>
            </router-link>
            <router-link to="/3d-privacy">
            <li class="p-3">Dataskyddspolicy</li>
            </router-link>
        </ul>
    </div>
</template>