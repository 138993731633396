<template>
  <div>
    <PrivacyPage />
  </div>
</template>

<script>
import PrivacyPage from '@/components/terms/hosting/privacy.vue'

export default {
  name: 'Privacy',
  components: {
    PrivacyPage
  }
}
</script>
