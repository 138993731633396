<template>
  <div>
    <SitemapPage />
  </div>
</template>

<script>
import SitemapPage from '@/components/uncommon/sitemap.vue'

export default {
  name: 'Sitemap',
  components: {
    SitemapPage
  }
}
</script>
