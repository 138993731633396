<template>
<div>
    <div data-aos="fade-up" data-aos-duration="1000" class="flex flex-col mx-auto mt-48 lg:text-left lg:justify-around lg:flex-row">
        <div class="text-center">
            <img class="mx-auto" src="../../assets/img/wz.png" alt="WinterZone AB">
            <p class="mt-6 font-medium text-gray-500">Keep cool, we'll be back!</p>

            <p class="mt-4 text-gray-500">
                ORG.NR: <span class="font-bold">559253-8861</span><br>
                VAT (SE): <span class="font-bold">SE559253886101</span>
            </p>
        </div>
        <div class="flex justify-center mt-12 text-center lg:text-left">
            <div class="flex flex-col">
                <p class="mb-4 font-medium tracking-wider text-gray-400 uppercase">Lösningar</p>
                <router-link class="mb-4" to="/3d">
                    <a class="mb-4 text-gray-500 hover:text-green-400">WinterZone 3D</a>
                </router-link>
                <a class="mb-4" href="https://shop.winterzone.se">
                    <a class="mb-4 text-gray-500 hover:text-yellow-200">WinterZone 3D - Webbshop</a>
                </a>
                <router-link class="mb-4" to="/interactive">
                    <a class="text-gray-500 hover:text-blue-400">WinterZone Interactive</a>
                </router-link>
                <router-link class="mb-4" to="/hosting">
                    <a class="text-gray-500 hover:text-pink-400">WinterZone Hosting</a>
                </router-link>
                <a class="text-gray-500 hover:text-yellow-400" href="https://studiesdb.com">StudiesDB</a>
            </div>
        </div>
        <div class="flex justify-center mt-12 text-center lg:text-left">
            <div class="flex flex-col">
                <p class="mb-4 font-medium tracking-wider text-gray-400 uppercase">Kommunikation</p>
                <a class="mb-4 text-gray-500">support@winterzone.se</a>
                <!--<a href="https://status.winterzone.se" class="mb-4 text-gray-500 hover:text-green-600">Driftstatus</a>-->
                <a href="https://forum.winterzone.se" class="mb-4 text-gray-500 hover:text-green-600">Forum och Information</a>
                <a href="https://anchor.fm/winterzone" class="mb-4 text-gray-500 hover:text-green-600">Podcast: Primör Entreprenör</a>
                <a class="text-gray-500 font-flow">Förändringar sker</a>
            </div>
        </div>
    <div class="flex justify-center mt-12 text-center lg:text-left">
        <div class="flex flex-col">
            <p class="mb-4 font-medium tracking-wider text-gray-400 uppercase">Företaget</p>
                <router-link class="mb-4" to="/contact">
                    <a class="text-gray-500 hover:text-blue-400">Kontakta oss</a>
                </router-link>
            <a class="mb-4 text-gray-500 font-flow">Blogg</a>
            <router-link to="/sitemap">    
                <a class="mb-4 text-gray-500 hover:text-yellow-300">Sitemap</a>
            </router-link>
        </div>
    </div>
    <div class="flex justify-center mt-12 text-center lg:text-left">
        <div class="flex flex-col">
            <p class="mb-4 font-medium tracking-wider text-gray-400 uppercase">Villkor</p>
                <router-link class="mb-4" to="/3d-terms">
                    <a class="mb-4 text-gray-500 hover:text-green-400">WinterZone 3D</a>
                </router-link>
                <router-link class="mb-4" to="/interactive">
                    <a class="text-gray-500 hover:text-blue-400">WinterZone Interactive</a>
                </router-link>
                <router-link class="mb-4" to="/hosting-terms">
                    <a class="text-gray-500 hover:text-pink-400">WinterZone Hosting</a>
                </router-link>
        </div>
    </div>
  </div>

  <div class="mx-auto text-center">
    <hr class="mx-auto mt-16 mb-8 w-11/12">
    <p class="mb-12 text-gray-400">© 2021-2023 WinterZone AB. All rights reserved.</p>
  </div>
</div>
</template>