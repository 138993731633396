<template>
    <div class="pl-20 pr-20">
        <h1 class="text-center mt-8 text-3xl text-pink-300 pt-8">Service Level Agreement</h1>
        <p class="text-gray-500 italic text-center">Amended: 2021-04-13</p><br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>1.0</strong> Overview</h2><br>
        <p>
            <strong>1.1</strong> WinterZone AB is committed to delivering all services to our paying clientele with 100% uptime. 
                WinterZone AB offers guaranteed 99.95% uptime via this Service Level Agreement, also referred to in this document as “SLA”. This SLA is 
                provided as a supplement to the Terms and Conditions you agreed to when becoming a WinterZone AB customer. This uptime guarantee is 
                applicable on a per-service-item and is not applicable for your entire monthly invoice. The SLA covers all services WinterZone AB offers 
                and will offer in the future, with the exclusion of the services mentioned in the Service Level Agreement Exclusions and Limitations 
                part of this agreement.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>2.0</strong> Service Downtime & How it is Measured</h2><br>
        <p>
            <strong>2.1</strong> Service Downtime is any unplanned interruption in Service Availability during which the Client is 
                unable to access any services offered by WinterZone AB, provided the interruption is determined to have been caused by a problem in the 
                immediate WinterZone AB services and or networking services as confirmed by WinterZone AB management. Downtime is measured as the total 
                length of time of the unplanned interruption in Service Availability during a calendar month. WinterZone AB is not responsible for any 
                downtime that is caused by any third party software that client utilizes.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>3.0</strong> Service Level Agreement Exclusion(s) and Limitation(s)</h2><br>
        <p><strong>3.1</strong> WinterZone AB management appreciate and care about all our paying clientele but some limitations and/or 
                exclusions must be included to this agreement as some events are out of our control. 
                <br>Therefore, Service Level Credit, refunds or account credits are not applicable to:
                <br><br>
                    <ol class="list-decimal ml-10">
                        <li>Acts of God; in the event downtime is suffered due to extreme weather at datacenters and or any other means defined by legislation 
                        will not be covered.</li>
                        <li>Accessibility of WinterZone AB’s web property, DNS servers, API, or control panel.</li>
                        <li>Any unlawful activities against the client and or caused by the client; such as DDOS attacks, server illegal activities and terminations.</li>
                        <li>Internet unavailability due to client issues; In the event the client’s own internet service provider and or uptime is interrupted not 
                        caused by WinterZone AB.</li>
                        <li>And/or exceeding resources allocated by the Clients plan; In the event the client’s server is not working due to their service requiring 
                        more resources than purchased. </li>
                        <li>Dedicated servers are not covered by the SLA.</li>
                        <li>Managed Vitrual Private Servers are not covered by the SLA.</li>
                        <li>If WinterZone AB believes the SLA claim to be fraudulent.</li>
                    </ol>
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>4.0</strong> Outage Credits</h2><br>
        <p>
            <strong>4.1</strong> Service level agreement credit is available after a service has been unreachable for more than 30 consecutive 
                minutes. For every 15 minutes of consecutive downtime after 30 minutes has elapsed the client will be credited 1% of the effected services’ monthly 
                bill, up to 100% of the value of the service. Quarterly and semiannual services will be able to claim for their individual monthly value not their 
                overall quarterly or semiannual value. However, WinterZone AB must still determine, in its sole discretion, whether or not an eligible outage has 
                occurred.</p><br>
                <table class="border-collapse border table-auto">
                    <thead>
                        <tr>
                            <th class="border p-2">Outage Time Frame</th>
                            <th class="border p-2">Credited amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="p-4">
                            <td class="border p-2 text-center">30 – 45 minutes</td>
                            <td class="border p-2 text-center">1% of monthly invoice</td>
                        </tr>
                        <tr>
                            <td class="border p-2 text-center">45 – 60 minutes</td>
                            <td class="border p-2 text-center">2% of monthly invoice</td>
                        </tr>
                        <tr>
                            <td class="border p-2 text-center">60 – 75 minutes</td>
                            <td class="border p-2 text-center">3% of monthly invoice</td>
                        </tr>
                        <tr>
                            <td class="border p-2 text-center">Etc...</td>
                        </tr>
                        <tr>
                            <td class="border p-2 text-center">24+ hours</td>
                            <td class="border p-2 text-center">100% of monthly invoice</td>
                        </tr>
                    </tbody>
                </table>
        <br><br>
                <h2 class="text-pink-300 text-xl font-bold"><strong>5.0</strong> How to Obtain Credits</h2><br>
        <p>
            <strong>5.1</strong> In order to receive any credit offered under this SLA, you must initiate a support ticket related to the 
                event AND expressly request that We issue a credit. DO NOT ASSUME THAT WE ARE AWARE OF YOUR OUTAGE. Your outage may be wholly unrelated to 
                WinterZone AB’s services, so unless you contact us via a support ticket, we may not be aware any problem exists. Valid ways to open a ticket and 
                request SLA credit are either via our Discord support chat or on our billing interface located at client.winterzone.se. If WinterZone AB was not 
                aware of an outage the timestamp of your ticket will mark the beginning of the outage. If WinterZone AB was aware of an outage, WinterZone AB may
                notify you of the outage. In the last case, you are still required to open a ticket to request SLA credit. You agree and acknowledge that all other
                terms, limitations, exclusions, disclaimers and requirements contained in WinterZone AB’s Hosting Terms and Conditions apply to this SLA.
        </p>
    </div>
</template>