<template>
  <div>
    <TermsPage />
  </div>
</template>

<script>
// @ is an alias to /src
import TermsPage from '@/components/terms/hosting/TermsPage.vue'

export default {
  name: 'hosting',
  components: {
    TermsPage
  }
}
</script>
