<template>
    <div>
        <h1 class="text-3xl text-center p-12">Sitemap</h1>
        <div class="flex flex-col md:flex-row md:justify-around">
            <div class="py-12 md:py-0">
                <h2 class="uppercase text-2xl text-green-300 font-bold text-center mb-4">Winterzone 3D</h2>
                <ul class="text-center text-black">
                    <router-link to="/3d">
                        <li class="p-1 hover:text-green-300 underline">WinterZone 3D</li>
                    </router-link>
                    <li class="p-1 hover:text-green-300 underline">Färgval</li>
                    <li class="p-1 hover:text-green-300 underline">Material</li>
                    <li class="p-1 hover:text-green-300 underline">Additiv tillverkning</li>
                    <router-link to="/3d-terms">
                        <li class="p-1 hover:text-green-300 underline">Villkor för WinterZone 3D</li>
                    </router-link>
                </ul>
            </div>
            <div>
                <h2 class="uppercase text-2xl text-blue-300 font-bold text-center mb-4">Winterzone Interactive</h2>
                <ul class="text-center text-black">
                    <router-link to="/interactive">
                        <li class="p-1 hover:text-blue-300 underline">WinterZone Interactive</li>
                    </router-link>
                    <router-link to="/contact">
                        <li class="p-1 hover:text-blue-300 underline">Kontakta oss</li>
                    </router-link>
                </ul>
            </div>
            <div class="py-12 md:py-0">
                <h2 class="uppercase text-2xl text-pink-300 font-bold text-center mb-4">Winterzone Hosting</h2>
                <ul class="text-center text-black">
                    <router-link to="/hosting">
                        <li class="p-1 hover:text-pink-300 underline">WinterZone Hosting</li>
                    </router-link>
                    <li class="p-1 hover:text-pink-300 underline">Minecraft</li>
                    <router-link to="/hosting-terms">
                        <li class="p-1 hover:text-pink-300 underline">Villkor för Hosting</li>
                    </router-link>
                </ul>
            </div>
        </div>
    </div>
</template>