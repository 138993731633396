<template>
  <div>
    <app-header />
    <router-view/>
    <app-footer />
  </div>
</template>

<style src="./assets/css/dist.css"/>

<script>
import AppHeader from '@/components/common/Navbar.vue'
import AppFooter from '@/components/common/Footer.vue'

export default {
    components:{
      AppHeader,
      AppFooter
  }
}
</script>