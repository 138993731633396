<template>
  <div>
    <TosPage />
  </div>
</template>

<script>
import TosPage from '@/components/terms/hosting/tos.vue'

export default {
  name: 'Tos',
  components: {
    TosPage
  }
}
</script>
