<template>
    <div class="pl-20 pr-20">
        <h1 class="text-center mt-8 text-3xl text-pink-300 pt-8">Terms of Service</h1>
        <p class="text-gray-500 italic text-center">Amended: 2021-04-13</p><br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>1.0</strong> Overview</h2><br>
        <p>
            <strong>1.1</strong> This website is operated by WinterZone AB. Throughout the site, the terms “we”, “us” and “our” refer to WinterZone AB. 
                WinterZone AB offers this website, including all information, tools and services available from this site to you, the user, 
                conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
        </p><br>
        <p><strong>1.2</strong> By visiting our site and/or purchasing something from us, 
                you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”, "ToS"), 
                including those additional terms and conditions and policies referenced herein and/or available by hyperlink. 
                These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, 
                and/ or contributors of content.
        </p><br>
        <p>
            <strong>1.3</strong> Please read these Terms of Service carefully before accessing or using our website. 
                By accessing or using any part of the site, you agree to be bound by these Terms of Service. 
                If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. 
                If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
        </p>
        <br>
        <p>
            <strong>1.4</strong> Any new features or tools which are added to the current store shall also be subject to the Terms of Service. 
                You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, 
                change or replace any part of these Terms of Service by posting updates and/or changes to our website. 
                It is your responsibility to check this page periodically for changes. 
                Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>2.0</strong> Termination & Suspension</h2><br>
        <p><strong>2.1</strong> We reserve the right to terminate/suspend your server(s) or service at any time upon violation of the terms of service, 
                or any other basis if needed. If a server is found abusing our service we have the right to suspend the server. 
                Abusing may include but not limit to the following:
                <br/><br>
                <ol class="list-decimal ml-10">
                    <li>High CPU Usage</li>
                    <li>Cause harm to the server</li>
                    <li>Discussion of illegal activities (voice)</li>
                    <li>Using the service for something it is not designed for</li>
                    <li>Distributed Denial of Service Attacks (DDOS)</li>
                </ol>
        </p><br>
        <p>
            <strong>2.2</strong> Your service will be suspended immediately after the last due date. 
                Files will be deleted 7 days after the last due date. You can find the due date on the invoice.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>3.0</strong> Payments & Refunds</h2><br>
        <p><strong>3.1</strong> All our services include a 24 hour refund policy. 
                If the first 24 hours have been exceeded since the initial first payment, we cannot offer any refund. 
                If we suspect abuse of this policy we may hold the refund for up to 5 business days or it will be paid out in account credits. 
                To complete the refund the customer must fill in a <a class="text-pink-300 hover:text-pink-400 underline" href="https://hosting.winterzone.se/model%20withdrawl%20form.pdf">model withdrawal form</a>.
        </p><br>
        <p>
            <strong>3.2</strong> Services may be canceled by the customer at any time, 
                however we do not provide a refund for the remaining time of the billing cycle outside our 24 hour refund period. 
                To receive a refund within our refund period the proper procedure has to be followed. 
                A charge back or dispute of any payment will result in the immediate suspension or termination of your service(s). 
                Your account may also be closed.
        </p>
        <br>
        <p>
            <strong>3.3</strong> To provide continuous service, WinterZone AB automatically renews all paid subscriptions upon expiration (if activated in the billing area). 
                Such renewals are generally for the same duration as the original subscription term (for example, a 1-month subscription will renew on a monthly basis, 
                a 3-month subscription will renew on a 3-month basis, and so on). By using the Site, 
                you acknowledge that your account will be subject to the above-described automatic renewals.
        </p>
        <br>
        <p>
            <strong>3.4</strong> Overcharged payments or uncanceled subscriptions may be refunded outside the regular refund period if sufficient 
                proof can be provided that everything was done to prevent this situation. It is possible to receive a full-cash refund if a refund request is received 
                within 7 days since the overcharged or uncanceled payment was made. After these 7 days it is only possible to receive a refund based on account credits. 
                If no refund request has been received within 3 months the payment will be forfeit and you will not be able to receive any form of refund. Note: Refunds 
                will be processed through the same gateway as the payment that was made.
        </p>
        <br><br>
        <h2 class="text-pink-300 text-xl font-bold"><strong>4.0</strong> Downtime & DDoS</h2><br>
        <p>
            <strong>4.1</strong> We do not have a 100% uptime guarantee for your service(s). We do however try to resolve any issues as soon as 
                possible. We are not held responsible if your server is down due to user generated mistakes. We are also not responsible for any loss of revenue that 
                might be caused due to the downtime.
        </p><br>
        <p>
            <strong>4.2</strong> We are not responsible for any downtime caused by a DDoS (Distributed Denial of Service) or DoS (Denial of Service) 
                attacks. If a DDoS or DoS attack is targeted at a specific client service, this may result in the targeted service being suspended until further notice.
        </p><br><br>
                <h2 class="text-pink-300 text-xl font-bold"><strong>5.0</strong> Storage & Data Loss</h2><br>
        <p>
            <strong>5.1</strong> WinterZone AB provides Unlimited SSD storage within fair use. If your server is using unreasonable amounts of storage
                 we will contact you. Large backups or logs may be deleted if they use a considerable amount of space.
        </p>
        <br>
        <p>
            <strong>5.2</strong> We are not to be held responsible for any data losses. We recommend users to make regular off-site backups of their 
                servers in case of faulty machines, bad modifications, etc. We do not provide backup recoveries. If the data was lost from our side we will do our best to 
                recover as much data as we can. If this is not possible we will make sure to make arrangements with the affected clients, this may include free credits in 
                the billing system or refunds.
        </p><br><br>
        <h2 class="text-pink-300 text-xl font-bold">Privacy Policy</h2>
        <router-link to="/hosting-privacy">
            <a class="text-pink-300 underline">You can find our privacy policy through this hyperlink.</a>
        </router-link><br><br>
    </div>
</template>