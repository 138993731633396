<template>
    <div>
        <h1 class="text-center mt-10 text-2xl">Villkor för WinterZone Hosting</h1>
        <ul class="flex flex-col text-center md:flex-row md:justify-around underline text-blue-300 p-12">
            <router-link to="/hosting-tos">
                <li class="p-3">Försäljningsvillkor</li>
            </router-link>
            <router-link to="/hosting-sla">
                <li class="p-3">Service-level agreement</li>
            </router-link>
            <router-link to="/hosting-privacy">
            <li class="p-3">Dataskyddspolicy</li>
            </router-link>
        </ul>
    </div>
</template>