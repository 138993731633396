<template>
  <div>
    <privacy />
  </div>
</template>

<script>
// @ is an alias to /src
import privacy from '@/components/terms/3d/privacy.vue'

export default {
  name: '3d',
  components: {
    privacy
  }
}
</script>
