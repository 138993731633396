<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div class="relative z-0 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg class="hidden absolute inset-y-0 right-0 w-48 h-full text-white transform translate-x-1/2 lg:block" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>


          <main class="z-0 px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div class="sm:text-center lg:text-left">
              <router-link to="/data">
                <a href="">
                  <!--<p class="p-2 mb-6 text-lg text-white bg-red-500 rounded-2xl"><b>Observera</b>: arg</p>-->
                </a>
              </router-link>
              <h1 class="text-5xl font-extrabold tracking-tight text-center text-gray-900 md:text-6xl sm:text-left">
                <span class="block xl:inline">Vi är </span>
                <span class="block text-pink-300 xl:inline">WinterZone AB</span>
              </h1>
              <p class="mt-12 text-base text-center text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 sm:text-left">
                WinterZone AB grundades mitt i en coronapandemi som ett familjeföretag med verksamhet inom <a class="text-green-300 underline" href="#/3d">3D-printning</a>, <a class="text-pink-300 underline" href="#/hosting">hosting</a> men också <a class="text-blue-300 underline" href="#/interactive">webb- och apputveckling</a>.</p>
                
              <p class="mt-24 text-base text-center text-gray-600 sm:mt-8 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-10 md:text-xl lg:mx-0 sm:text-left">
                <span class="p-1 px-2 mx-auto text-sm font-bold text-center text-black bg-yellow-400 rounded">UNDER UTVECKLING</span><br><br>
                Sedan dess har vi även påbörjat ett arbete för att förenkla gymnasieelevers studier genom att bygga en digital plattform för att planera, organisera och påminna om studier.
                Målet är att minimera friktionen för elever att utföra sina hemstudier såväl som studier i skolan genom att samla allt på en central plattform för att på så vis slippa leta efter rätt sida eller information.
                <br><br><span class="font-medium text-gray-700">Är du intresserad av att underlätta dina studier? Vill du vara med och bygga en plattform av gymnasieelever för gymnasieelever? Besök <a class="text-yellow-300 underline" href="https://studiesdb.com">studiesdb.com</a> för att se vad som händer i projektet.</span>
              </p>
            
            </div>
          </main>
        </div>
      </div>

    </div>
  </div>  
</template>