<template>
        <!-- This example requires Tailwind CSS v2.0+ -->
    <div x-data="{ open: false }" class="relative bg-white">
      <div class="px-4 mx-auto max-w-7xl sm:px-6">
        <div class="flex justify-between items-center py-6 border-b-2 border-gray-100 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span class="sr-only">Workflow</span>
              <img class="w-auto h-8 sm:h-10" src="../../assets/img/wz.png" alt="WinterZone AB">
            </a>
          </div>
          <div class="-my-2 -mr-2 md:hidden">
            <button @click="toggle" type="button" class="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Open menu</span>
              <!-- Heroicon name: menu -->
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav class="hidden space-x-10 md:flex">
            <router-link to="/">
              <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900">
                Hem / Om oss
              </a>
            </router-link>
            <div class="relative">
              <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
              <button @click="toggle" type="button" class="inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span>Lösningar</span>
                <!--
                  Heroicon name: chevron-down

                  Item active: "text-gray-600", Item inactive: "text-gray-400"
                -->
                <svg class="ml-2 w-5 h-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>

              <!--
                'Solutions' flyout menu, show/hide based on flyout menu state.

                Entering: "transition ease-out duration-200"
                  From: "opacity-0 translate-y-1"
                  To: "opacity-100 translate-y-0"
                Leaving: "transition ease-in duration-150"
                  From: "opacity-100 translate-y-0"
                  To: "opacity-0 translate-y-1"
              -->
              <div v-if="active" class="absolute z-10 px-2 mt-3 -ml-4 w-screen max-w-md transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                <div class="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 shadow-lg">
                  <div class="grid relative z-20 gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                    <router-link to="/3d">
                      <a href="#" class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                        <!-- Heroicon name: chart-bar -->
                        <svg class="flex-shrink-0 w-6 h-6 text-green-300" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                        </svg>
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            WinterZone 3D
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Både ritar och skriver ut 3d-filer on-demand. Allt tack vare 3d-printers.
                          </p>
                        </div>
                      </a>
                    </router-link>

                      <a href="https://shop.winterzone.se" class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                        <!-- Heroicon name: chart-bar -->
                      <svg class="flex-shrink-0 w-6 h-6 text-yellow-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            WinterZone 3D - Webbshop
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Vi har ett omfattande sortiment av modelljärnvägsrelaterade modeller i vår webshop.
                          </p>
                        </div>
                      </a>

                    <router-link to="/interactive">
                      <a href="#" class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                        <!-- Heroicon name: cursor-click -->
                        <svg class="flex-shrink-0 w-6 h-6 text-blue-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                        </svg>
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            WinterZone Interactive
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Smidiga web-baserade applikationer och hemsidor, ofta skräddarsydda.
                          </p>
                        </div>
                      </a>
                    </router-link>

                    <router-link to="/hosting">
                      <a href="#" class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                        <!-- Heroicon name: shield-check -->
                        <svg class="flex-shrink-0 w-6 h-6 text-pink-300" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path>
                        </svg>
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            WinterZone Hosting
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Premiumserverar för spelserverar såväl som hemsidor i Sverige.
                          </p>
                        </div>
                      </a>
                    </router-link>
                    <a href="https://studiesdb.com" class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50">
                      <!-- Heroicon name: shield-check -->
                      <svg class="flex-shrink-0 w-8 h-8 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z"/>
                        <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z"/>
                        <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z"/>
                      </svg>

                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900">
                          StudiesDB <span class="float-right p-1 px-2 ml-2 text-xs font-bold text-black bg-yellow-400 rounded">KOMMER SNART</span>
                        </p>
                        <p class="mt-1 text-sm font-medium text-gray-500">
                          Det digitala verktyget som hjälper dig att planera och organisera dina gymnasiestudier.
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
              <!--<a href="https://status.winterzone.se" class="text-base font-medium text-gray-500 hover:text-gray-900">
                Driftstatus
              </a>-->
              <a href="https://forum.winterzone.se" class="text-base font-medium text-gray-500 hover:text-gray-900">
                Forum och Information
              </a>
            <router-link to="/contact">
              <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900">
                Kontakt
              </a>
            </router-link>
            <!--<a href="#" class="text-black hover:text-green-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </a>-->
          </nav>
        </div>
      </div>

            <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
      <div x-data="{ open: false }" v-if="active" class="absolute inset-x-0 top-0 z-50 p-2 transition transform origin-top-right md:hidden">
        <div class="bg-white rounded-lg divide-y-2 divide-gray-50 ring-1 ring-black ring-opacity-5 shadow-lg">
          <div class="px-5 pt-5 pb-6">
            <div class="flex justify-between items-center">
              <div>
                <img class="w-auto h-8" src="../../assets/img/wz.png" alt="WinterZone AB">
              </div>
              <div class="-mr-2">
                <button @click="toggle" type="button" class="inline-flex justify-center items-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link to="/3d">
                  <a href="#" class="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <!-- Heroicon name: cursor-click -->
                    <svg class="flex-shrink-0 w-6 h-6 text-green-300" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
                    </svg>
                    <span class="ml-3 text-base font-medium text-gray-900">
                      WinterZone 3D
                    </span>
                  </a>
                </router-link>

                  <a href="https://shop.winterzone.se" class="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <!-- Heroicon name: shopping-cart -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-yellow-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    <span class="ml-3 text-base font-medium text-gray-900">
                      WinterZone 3D - Webbshop
                    </span>
                  </a>

                <router-link to="/interactive">
                  <a href="#" class="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <!-- Heroicon name: shield-check -->
                    <svg class="flex-shrink-0 w-6 h-6 text-blue-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                    </svg>
                    <span class="ml-3 text-base font-medium text-gray-900">
                      WinterZone Interactive
                    </span>
                  </a>
                </router-link>

                <router-link to="/hosting">
                  <a href="#" class="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <!-- Heroicon name: view-grid -->
                    <svg class="flex-shrink-0 w-6 h-6 text-pink-300" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path>
                    </svg>
                    <span class="ml-3 text-base font-medium text-gray-900">
                      WinterZone Hosting
                    </span>
                  </a>
                </router-link>
                <a href="https://studiesdb.com" class="flex items-center p-3 -m-3 rounded-md hover:bg-gray-50">
                    <svg class="flex-shrink-0 w-8 h-8 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z"/>
                        <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z"/>
                        <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z"/>
                      </svg>
                    <span class="ml-3 text-base font-bold text-gray-900">
                      StudiesDB
                    </span>
                    <span class="self-end p-1 px-2 mb-0.5 ml-2 text-xs font-bold text-black bg-yellow-400 rounded">KOMMER SNART</span>
                  </a>
              </nav>
            </div>
          </div>
          <div class="px-5 py-6 space-y-6">
            <div class="grid grid-cols-2 gap-y-4 gap-x-8">
              <router-link to="/">
                <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700">
                  Hem / Om oss
                </a>
              </router-link>

              <router-link to="/contact">
                <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700">
                  Kontakta oss
                </a>
              </router-link>

              <a href="#" class="text-base font-medium text-gray-900 font-flow hover:text-gray-700">
                Blogg
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
  export default {
    data () {
      return {
        active: false
      }
    },
    methods: {
      toggle () {
        this.active = !this.active
      }
    }
  }
</script>