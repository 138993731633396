<template>
    <div>
      <AttackPage />
    </div>
</template>
  
  <script>
    import AttackPage from '../components/AttackPage.vue';
  
  export default {
    name: 'Attack',
    components: {
    AttackPage
}
  }
  </script>
  