<template>
  <div>
    <InteractivePage />
  </div>
</template>

<script>
import InteractivePage from '@/components/InteractivePage.vue'

export default {
  name: 'Interactive',
  components: {
    InteractivePage
  }
}
</script>
