<template>
  <div>
    <ContactPage />
  </div>
</template>

<script>
import ContactPage from '@/components/ContactPage.vue'

export default {
  name: 'Contact',
  components: {
    ContactPage
  }
}
</script>
