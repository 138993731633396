<template>
    <div class="pl-20 pr-20">
        <h1 class="text-center mt-8 text-3xl text-green-300 pt-8">Försäljningsvillkor WinterZone AB</h1>
        <p class="text-gray-500 italic text-center">WinterZone AB:s Försäljningsvillkor gäller från och med 2020-12-04.</p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Allmänna villkor</h2><br>
        <p>Samtliga priser i våra prislistor är angivna inklusive moms. Alla priser är exklusive frakt där 
            inget annat anges. Vi reserverar oss mot eventuella felaktigheter och förändringar utom vår 
            kontroll. Notera att offerter kan innehålla speciella avtal. Efter att kund har tilldelats behörighet 
            att lägga order via WinterZone AB:s Webbshop ansvarar kunden fullt för alla transaktioner som görs via 
            kundens behörigheter i WinterZone AB:s Webbshop. Vid uppsägning eller spärr av användare gäller kundens 
            skyldigheter till dess WinterZone AB bekräftat uppsägningen skriftligt. Levererade varor förblir 
            WinterZone AB:s egendom till dess full likvid erlagts.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Prisändringar</h2><br>
        <p>Vi förbehåller oss rätten att ändra priser utan föregående avisering, t.ex. vid större förändringar 
            i valutakurser. Lagerförda produkter levereras alltid till bekräftat pris och förändras priset för 
            en lagerförd produkt mellan order och leverans, gäller priset som lämnats i WinterZone AB:s orderbekräftelse. 
            För produkter som vid orderläggning ej finns disponibla i lager, gäller orderbekräftat pris med reservation 
            för prisjusteringar från leverantör eller övriga omständigheter utom WinterZone AB:s kontroll. Efter 
            eventuell justering skall nytt pris godkännas av kund, i annat fall annulleras ordern.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Betalningsvillkor</h2><br>
        <p>För privatpersoner sker försäljning via WinterZone AB Webbshop sker med faktura, Swish, Paypal eller kreditkort 
            (vid direktbetalning belastas kontot direkt vid beställningstillfället). Försäljning till företag sker efter 
            särskild kreditprövning mot faktura som normalt förfaller till betalning 30 dagar från fakturadatum. Vid ej 
            godkänd kreditprövning gäller samma regler som för privatperson. Försäljning till stat, kommun och landsting 
            sker som regel mot faktura som förfaller 30 dagar från fakturadatum. För försäljning till omyndig eller minderårig 
            krävs målsmans skriftliga godkännande. Vid betalningsdröjsmål utgår en årlig dröjsmålsränta på utestående belopp 
            med 24% från förfallodagen samt en påminnelseavgift om 60 SEK. Anmärkningar mot utsänd faktura skall ske inom 10 
            dagar.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Leveranstid</h2><br>
        <p>Leverans sker så snart som möjligt efter order, dock senast enligt orderbekräftelse. Skulle leveranstiden 
            överskrida uppgiven maximal leveranstid har du som privatkonsument rätt att häva köpet och i de fall där 
            produkten eller produkterna är förskottsbetalade även rätt att få full återbetalning inom 20 dagar. Vid köp av 
            ej lagerförda eller restnoterade produkter kan uppgift om maximal leveranstid ej lämnas då detta ligger utom vår 
            kontroll. Skulle leveranstiden bli orimligt lång eller given leveranstid ej visat sig vara korrekt har du som 
            privatkonsument rätt att häva köpet och i de fall där produkten eller produkterna är förskottsbetalade även rätt 
            att få full återbetalning inom 20 dagar.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Leveranssätt</h2><br>
        <p>Leverans sker med av WinterZone AB utsedd speditör beroende på vikt och storlek, fritt vårt lager. Normal 
            leveranstid 1-3 vardagar. Avhämtning kan ske efter överenskommelse.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Fraktkostnad</h2><br>
        <p>För leverans debiteras fraktkostnader enligt speditörs fraktprislista. Uppgifter om kostnad för andra typer 
            av leveranser (t ex bud) lämnas av på begäran.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Outlöst försändelse</h2><br>
        <p>För försändelser som ej är utlösta debiteras en avgift om f n 150 kr inklusive moms för att täcka 
            WinterZone AB:s transport- och administrationskostnader.
        </p><br><br>
                <h2 class="text-green-300 text-xl font-bold">Transportskada</h2><br>
        <p>Kontrollera alltid er leverans vid mottagande Postnord. Om godset verkar skadat på grund av defekt kartong 
            packa alltid upp och kontrollera själva varan. Vid transportskadad produkt (fysisk skada på varan) skall 
            kunden omgående kontakta logistikpartnern och reklamera godset. Kontakta sedan WinterZone AB på e-post 
            support@winterzone.se och uppge order- eller fakturanummer samt speditörens reklamationsnummer.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Öppet köp</h2><br>
        <p>Hos WinterZone har du som privatkonsument 30 dagars öppet köp. Öppet köp innebär att varan skall finnas 
            i vårt ordinarie sortiment (ej specialbeställning) och vara i obruten originalförpackning.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Återköp</h2><br>
        <p>För vara ur det ordinarie sortimentet vars originalförpackning ej brutits eller på annat sätt skadats 
            eller använts, accepterar WinterZone AB återköp under 30 dagar från fakturadatum vid överenskommelse med 
            ansvarig säljare. Varor där originalförpackningen förstörts eller försvunnit, komponenter saknas eller när 
            varan är använd återköpes ej. Återköp accepteras ej heller för programvaror vars plombering eller försegling 
            (med försegling avses även t ex serienummer) brutits, för presentkort eller för produkter 
            specialbeställda/tillverkade för kundens räkning.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Ångerrätt (privatkonsument)</h2><br>
        <p>I enlighet med svensk lagstiftning om konsumentskydd vid distansavtal har privatkonsument 14 dagar på sig att 
            frånträda sitt köp från det att kunden har mottagit produkten. Kunden har rätt att öppna och kontrollera 
            varan. I de fall där värdeminskning skett vid öppnandet och kontrollen av varan kommer WinterZone AB debitera 
            kunden för denna värdeminskning. Ångerrätten gäller inte hygienartiklar, datorprogram, spel eller ljud- och 
            bildupptagningar då förseglingen/plomberingen brutits samt specialtillverkad produkt av oss. För en enklare 
            hantering bör kund före återsändandet kontakta WinterZone AB per e-post, observera att erhållande av 
            returnummer ej utgör ett godkännande av ångerrätt. Vid återsändandet skall returfrakten vara betald samt 
            med faktura/följesedel bifogad och i förekommande fall vara tydligt märkt med returnummer. Varan skall vara 
            förpackad i av PostNord godkänt ytteremballage (t.ex. wellpappkartong) och fixeras med packmaterial så att den 
            inkommer till WinterZone AB på ett säkert sätt och inte skadas under transporten på grund av bristfällig 
            emballering. Postnord har även en bra guide och den hittar ni här. Du kan även meddela WinterZone AB om 
            frånträdande av köp genom att fylla i och posta Konsumentverkets blankett till oss. Erhållande av returnummer 
            utgör inte godkännande av ångerrätt utan godkänns först när WinterZone AB mottagit produkten och funnit den 
            insänd i enlighet med ovanstående villkor. Vid fullt godkännande av ångerrätt återbetalas hela beloppet kunden 
            betalt för varan inklusive eventuella kostnader som gäller ursprungsleveransen inom 14 dagar.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Reklamation (privatkonsument)</h2><br>
        <p>I enlighet med svensk lagstiftning har privatkonsument rätt att reklamera varor som var felaktiga vid 
            överlämnandet av varan inom 3 år från inköpsdatum. Meddelande om reklamation skall lämnas till WinterZone AB 
            inom 2 månader från det att felet upptäckts. Fel som uppstår inom 6 månader från inköpsdatum anses vara 
            ursprungliga. Sker reklamation inte inom 3 år från inköpsdatum förlorar privatkonsumenten rätten att reklamera 
            varan.
        </p><br>
        <p>För en enklare hantering bör kund före återsändandet göra en felanmälan per e-post. Observera att erhållande av 
            returnummer ej utgör ett godkänt reklamationsärende. Vid återsändandet skall faktura/följesedel vara bifogad och 
            kartongen vara tydligt märkt med returnummer. Varan skall vara förpackad i av Posten godkänt ytteremballage 
            (t.ex wellpappkartong). Reklamationsärenden som inkommer till WinterZone AB utan godkänt ytteremballage 
            (t.ex. i kuvert) returneras till kund utan åtgärd då dessa ej kan godkännas för reklamationsåtgärd.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Garanti</h2><br>
        <p>Respektive tillverkares garantivillkor gäller, minimum 1 år samt upp till tillverkares angivna garantiperiod 
            beroende på produkt. Alla garantier som specificeras på är giltiga i Sverige och i de fall där produkten behöver 
            skickas till tillverkare utanför Sverige åtar sig WinterZone AB att bekosta frakt och utföra eventuellt 
            administrativt arbete.
        </p><br>
        <p>Ingen garanti lämnas normalt på mjukvara eller följdfel, som orsakats av felaktig eller defekt mjukvara och/eller 
            datamedia, såsom CD-, DVD-skivor eller disketter. Garanti lämnas heller ej på produkter som hanterats felaktigt 
            eller på annat sätt ej används på anvisat sätt.
        </p><br>
        <p>Vid återsändandet skall kund för en enklare hantering göra en felanmälan per e-post. Observera att erhållande 
            av returnummer ej utgör ett godkänt garantiärende. Vid återsändandet skall returfrakten vara betald samt med 
            faktura/följesedel bifogad och tydligt uppmärkt med returnummer. Varan skall vara förpackad i av Posten godkänt 
            ytteremballage (t ex wellpappkartong). Garantiärenden som inkommer till WinterZone AB utan godkänt ytteremballage 
            (t ex i kuvert) returneras till kund utan åtgärd då dessa ej kan godkännas för garantiåtgärd.
        </p><br>
        <p>Garanti ges först när WinterZone AB mottagit produkten och funnit den insänd i enlighet med ovanstående villkor.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Ansvar för fel</h2><br>
        <p>Föreligger det fel som WinterZone AB ansvarar för, åtar sig WinterZone AB att avhjälpa felet genom reparation, 
            omleverans eller i vissa fall att återbetala köpeskillingen. WinterZone AB kan komma att hänvisa kund direkt till 
            respektive tillverkare eller till en av denne anvisad serviceverkstad för felets åtgärdande i de fall där tillverkaren 
            åtagit sig att för WinterZone AB:s räkning avhjälpa fel i en vara. Ytterligare rättigheter för kund kan åberopas av 
            respektive tillverkares egna till slutkund riktade garantiåtaganden.
        </p><br>
        <p>WinterZone AB:s ansvar till följd av fel i vara är begränsat till vad som anges i dessa allmänna försäljningsvillkor. 
            WinterZone AB åtar sig således inget direkt eller indirekt ansvar för t ex inkompabilitet, produktansvar, utebliven 
            vinst, driftsavbrott, förlust av lagrad information, merarbete, skadebringande egenskaper eller annan ekonomisk skada.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Ansvar för fel (privatkonsument)</h2><br>
        <p>Föreligger det fel som WinterZone AB ansvarar för, åtar sig WinterZone AB att avhjälpa felet genom reparation, omleverans 
            eller i vissa fall att återbetala köpeskillingen. Ytterligare rättigheter för kund kan åberopas av respektive tillverkares 
            egna till slutkund riktade garantiåtaganden. WinterZone AB:s ansvar till följd av fel i vara är begränsat till vad som 
            anges i dessa allmänna försäljningsvillkor samt vad som anges i konsumentköplagen (SFS 1990:932).
        </p><br>
        <p>Kunden har rätt till ersättning för de kostnader som kan ha uppstått i samband med reklamation. Kunden har även rätt till 
            ersättning för de eventuella förluster eller mera betydande olägenheter och besvär som har uppstått i samband med fel 
            eller dröjsmål. För att erhålla ersättning måste du visa att varan är felaktig eller avlämnats för sent och att du verkligen 
            tillfogats en förlust. Du skall som kund vidta rimliga åtgärder för att begränsa din förlust samt försöka hålla nere 
            kostnaderna som uppstår i samband med reklamationen.
        </p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Skydd av personuppgifter</h2><br>
        <router-link to="/3d-dataskydd">
            <a class="text-green-300 underline">Klicka här för att läsa vår dataskyddspolicy.</a>
        </router-link><br><br>
        <p>I fall tvist uppstår mellan WinterZone AB och privatkonsument följer WinterZone AB alltid ARN:s rekommendationer.</p>
    </div>
</template>