<template>
    <div class="mx-auto max-w-2xl">
        <h1 class="mt-24 text-4xl">Hej,</h1>
        <br>
        <p>
            WinterZone AB har utsatts för utpressningsförsök. Vi kan ännu inte bekräfta att en attack har ägt rum, 
            men heller inte utesluta att den hackare som angripit oss fått åtkomst till personuppgifter. Dock 
            innehåller den attackerade databasen inga känsliga uppgifter utan endast namn, adress, mailadress, 
            telefonnummer samt vilka varor ni köpt. Ingen av dessa uppgifter bedömer vi som känsliga.
        </p>
        <br>
        <p>
            Kontokorts-, Paypal och Swishuppgifter finns i andra databaser som vi inte ens själva har tillgång till. 
            Således finns ingen risk för att kontokortsnummer eller liknande kan ha röjts genom angreppet. 
            Som kund kan du känna dig trygg, enda möjliga konsekvensen vi ser för dig som kund är att det kan 
            uppstå funktionsstörningar i vår webshop och en ökad risk för att du kan få spam-mail från vår utpressare. 
        </p>
        <br>
        <p>
            Ditt lösenord hos oss är krypterat och går därmed inte att utläsa mänskligt. Vi uppmuntrar dock till att 
            du regelbundet byter lösenord och speciellt efter minsta misstanke om intrång. Vi påminner också om att 
            det är en bra säkerhetsåtgärd att aldrig använda samma lösenord på olika plattformar.
        </p>
        <br>
        <p>
            Vi gör de säkerhetsuppdateringar av mjukvaror som vi kan, men det hindrar inte att det finns okända 
            säkerhetshål i mjukvaror. Vår webshop bygger på en välrenommerad mjukvarulösning och vi är således en av 
            många webshoppar som utsatts för denna typ av attack. I stället för att som många andra företag tysta ner 
            incidenter är vår policy är att vara transparanta mot dig som kund och informera, men vi vill återigen 
            understryka att det inte finns någon risk för att utpressaren kommit åt känsliga uppgifter såsom 
            kontokortsuppgifter.
        </p>
        <br>
        <p>
            Händelsen är polisanmäld och anmäld som personuppgiftsincident till Integritetsskyddsmyndigheten. 
            Tyvärr är denna typ av brott mycket vanliga men leder så gott som aldrig till åtal. 
            Vi beklagar det inträffade och tar det på största allvar. Vi fortsätter att arbeta med att förebygga angrepp.
        </p>
        <br>

        <p>Martin Peterson, VD</p>
        <br><br><br>
        <h1 class="mt-24 text-4xl">Hej,</h1>
        <br>
        <p>Skulle det vara några som helst frågor går det bra att höra av sig till support@winterzone.se
        eller i chattbubblan nera i högra hörnet. Jag vill även ta tillfället i akt att ännu en gång beklaga händelsen och understryka att
        vi gör allt i vår makt för att era uppgifter ska lagras säkert. En möjlig faktor är i samband med en ny säkerhetsuppdatering 
        från PrestaShop - lösningen vi använder för vår webshop - som för ett tag sedan fick en säkerhetsuppdatering mot just denna typ
        av attack (om den ens ägt rum). Vi har hållt vår webshop uppdaterad men det skulle inte kunna hindra någon från att göra en attack innan
        utvecklarna av PrestaShop upptäckt säkerhetshålets existens och "täppt igen det". 
        </p>
        <br>
        <p>
        En annan möjlig faktor är att någon låtsas gjort en attack
        i samband med att den fått reda på säkerhetshålets tidigare existens och sedan utkräver bitcoin då medvetenheten om att säkerhetshållet 
        funnits nu är stor och företag är rädda för att få kunders data läckt. Det som pekar mot detta är att vi inte fått något bevis på att
        hackaren har någon av er data utan bara fått ett meddelande som säger något i stil med "betala, annars". 
        </p>
        <br>
        <p>
        Fram tills dess att vi fått det bekräftat att hackaren har data så tänker vi utgå från att hackaren inte har någon information men vill gärna informera om händelsen
        och uppmuntra till att ni praktiserar en god standard för cybersäkerhet. Tänk på att byta lösenord ofta, ha olika lösenord på olika
        plattformar med mera. Vi kan rekommendera tjänster såsom "lösenordshanterare" (password managers) såsom Bitwarden, NordPass, Avira 
        med flera, dessa gör det enklare att hantera många och olika lösenord.
        </p>
        <br>

        <p>Sixten Peterson, CTO / Teknisk chef</p>
    </div>
</template>