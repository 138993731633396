<template>
    <div class="pl-20 pr-20">
        <h1 class="text-center mt-8 text-3xl text-green-300 pt-8">Försäljningsvillkor 3D-utskriftsservice WinterZone AB</h1>
        <p class="text-gray-500 italic text-center">WinterZone AB:s Försäljningsvillkor gäller från och med 2020-12-04.</p><br><br>
        <p>WinterZone AB erbjuder utskriftsservice, nedan kallat tjänsten, av 3D-objekt, nedan 
            kallat 3D- utskrifter till företag och privatpersoner, nedan kallat kunden. För 
            tjänsten tillämpas WinterZone AB:s generella försäljningsvillkor med följande 
            tillägg:</p><br>
        <p>Utskriftsservicen innebär att kunden köper råmaterialet från WinterZone AB samt 
            hyr maskintid inklusive operatör från WinterZone AB för att skriva ut 
            3D-utskrifter. Kunden tillhandahåller utskriftunderlaget i form av en 3D-fil 
            i filformaten OBJ, STL eller 3DMF. Andra filformat kan efter överenskommelse 
            med WinterZone accepteras mot tilläggskostnad för filkonvertering. Kunden ansvarar
             för att 3D-filen är korrekt, fullständig och felfri. WinterZone förbehåller sig 
             rätten att avvisa utskriftsuppdrag om filen vid beredning eller utskrift visar sig 
             innehålla fel. Upparbetad kostnad debiteras i dessa fall kunden. WinterZone tar 
             inte ansvar för kundens användning av utskrivna 3D- modeller då WinterZone inte har 
             kontroll över hur kunden använder 3D-modellerna.</p><br>
        <p>WinterZone förbehåller sig rätten att orientera modellen på det sätt vi bedömer vara 
            bäst ur utskriftssynpunkt såvida kunden inte meddelat annat. Utskriftsorienteringen 
            kan dock påverka hållfasthet och ytor, vid tveksamhet rådgör med oss innan utskrift.</p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Generella råd om 3D-utskrifter</h2><br>
        <p>3D-utskrifter har lägre hållfasthet än plastdetaljer framtagna med traditionella 
            tillverkningsmetoder, 3D-utskrifter är anisotropa, d.v.s. hållfastheten är lägre 
            längs utskriftslagren än tvärs desamma. 3D- utskrifter skall därför inte användas 
            för applikationer som utsätts för mekaniska laster. 3D-utskrifter har porös yta 
            vilket gör dem olämpliga för kontakt med livsmedel då det är svårt att hålla utskrifterna 
            rena och bakteriefria. Våra skrivare innehåller dessutom delar av mässing som inte är 
            garanterat blyfria. Olika plastmaterial har olika tålighet mot temperatur och 
            miljöfaktorer såsom kemikalier och UV-ljus. Rådgör med WinterZone AB om materialval 
            om utskrifterna skall utsättas för miljöpåverkan.</p><br>
        <p>3D-utskrifter har synliga lagerlinjer, dessa varierar med utskriftsupplösning, 
            3D-modelldesign och materialval. För höga krav på ytjämnhet kan utskriften behöva 
            spacklas och slipas sådant arbete kan utföras av WinterZone mot pristillägg. Vill du 
            slipa själv rekommenderar vi våtslipning. För vissa utskriftsgeometrier måste stödmaterial 
            användas av tekniska skäl, vid dessa geometrier kan ytstrukturen påverkas. Kunden ansvarar 
            för borttagning av stödmaterial om inte annat överenskommits. Vid vissa geometrier kan det 
            bildas tunna trådar av materialet s.k. stringing. Dessa kan tas bort med 
            varmluftsbehandling exempelvis med en varmluftspistol. Värmebehandlingen måste vara snabb, 
            någon sekund räcker annars kan detaljen deformeras. Borttagning av stingning ingår inte i 
            utskriftsuppdraget om annat inte överenskommits.</p><br><br>
        <h2 class="text-green-300 text-xl font-bold">Immaterialrätt</h2><br>
        <p>Kunden ansvar för immateriella rättigheter som exempelvis, men inte begränsat till; copyright, 
            varumärkesskydd och patent på tillhandahållna utskriftsunderlag. WinterZone kan inte belastats 
            för eventuella intrång immateriella rättigheter på av kunden tillhandahållet material. 
            WinterZone AB äger copyright och övriga immateriella rättigheter på av kunden beställt 
            konstruktions- eller designuppdrag, om inte annat skriftligen avtalats mellan WinterZone och 
            kunden. Winterzone förbehåller sig rätten att använda foton och filer av kundens utskrifter i 
            marknadsföringsssyfte.</p>
    </div>
</template>